 /* eslint-disable */
import React from 'react'
import { graphql, Link } from 'gatsby'

import Img from 'gatsby-image'


import SEO from "../components/seo"
import Header from "../components/header"


import Footer from "../components/footer"





export default ({ data, pageContext: { node, locale, pagePath } }) => {



    let pageData = data.pageData.edges[0].node
    let globalData = data.globalData.edges[0].node


    let page_title_local = pageData.frontmatter[locale + "_title_01"] + " | " + globalData.frontmatter[locale + "_page_title_wooden_doors_prefix"] + " | " + globalData.frontmatter[locale + "_page_title_global_prefix"] + " " + globalData.frontmatter[locale + "_page_title_since_2001_prefix"]  
    let page_meta_description = pageData.frontmatter[locale + "_meta_desc"]
    let page_meta_keywords = pageData.frontmatter[locale + "_meta_keywords"]

    
    return (
        <>
            <SEO 
                description={page_meta_description}
                keywords={page_meta_keywords}
                pathname={pagePath}
                title={page_title_local}
            />

            <Header 
                locale={locale}
                pagePath={pagePath}
             />

            <div class="container">
                <div className="row">

                    <div className="col-12 col-md-4">
                        <h1>{pageData.frontmatter[locale + "_title_01"]}</h1>
                        <p>{pageData.frontmatter[locale + "_text_01"]}</p>
                    </div>
                    <div className="col-12 col-md-8">
                        <a href={'https://www.instagram.com/design.artdoor/'} target="_blank">
                            <Img alt={page_meta_description} fixed={globalData.frontmatter.img_instagram.childImageSharp.fixed} />
                        </a>
                    </div>
                </div>
            </div>



            <Footer 
                locale={locale}
                pagePath={pagePath}
             />


</>
    
    )
}




export const md_our_work = graphql`query md_our_work {
    pageData:
    allMarkdownRemark(filter: {frontmatter: {type: {eq: "page_our_work"}}}) {
      edges {
        node {
          frontmatter {
            slug
            he_meta_desc
            he_meta_keywords
            he_title_01
            he_text_01
            pr_meta_desc
            pr_meta_keywords
            pr_title_01
            pr_text_01
            pr_title_fax
            pr_text_fax
            es_meta_desc
            es_meta_keywords
            es_title_01
            es_text_01
            ru_meta_desc
            ru_meta_keywords
            ru_title_01
            ru_text_01
            fr_meta_desc
            fr_meta_keywords
            fr_title_01
            fr_text_01
            en_meta_desc
            en_meta_keywords
            en_title_01
            en_text_01

          }
        }
      }
    }
    globalData: 
    allMarkdownRemark(filter: {frontmatter: {type: {eq: "global_data"}}}) {
        edges {
            node {
                id
                frontmatter {
                    he_page_title_global_prefix
                    he_page_title_since_2001_prefix
                    he_page_title_wooden_doors_prefix
                    he_door_series
                    fr_page_title_global_prefix
                    fr_page_title_since_2001_prefix
                    fr_page_title_wooden_doors_prefix
                    fr_door_series
                    en_page_title_global_prefix
                    en_page_title_since_2001_prefix
                    en_page_title_wooden_doors_prefix
                    en_door_series
                    pr_page_title_global_prefix
                    pr_page_title_since_2001_prefix
                    pr_page_title_wooden_doors_prefix
                    pr_door_series
                    es_page_title_global_prefix
                    es_page_title_since_2001_prefix
                    es_page_title_wooden_doors_prefix
                    es_door_series
                    ru_page_title_global_prefix
                    ru_page_title_since_2001_prefix
                    ru_page_title_wooden_doors_prefix
                    ru_door_series
                    img_instagram {
                        childImageSharp {
                            fixed(width: 878) {
                                ...GatsbyImageSharpFixed
                            }
                        }
                    }


                }
            }
        }
    }
  }`